import { InfoCircleOutlined } from '@ant-design/icons';
import { Product } from '@omniafishing/core';
import { Tooltip } from 'antd';
import React from 'react';
import { isDone } from '../../constants/loading_state';
import { useShipping } from '../../hooks/use_shipping';
import { SHIPPING_EXPRESS_PRICE_DOLLARS } from '../../redux/cart';

interface ShippingEstimatorProps {
  product: Product;
  availableForSale: boolean;
}

export const ShippingEstimator = (props: ShippingEstimatorProps) => {
  const { product, availableForSale } = props;

  const { dateExpress, dateGround, loadingState, shippingCost, showExpress } = useShipping(product);

  if (!product || !isDone(loadingState) || !availableForSale) {
    return null;
  }

  return (
    <div>
      <p className="pb-2 flex justify-between">
        <span className="text-black/55">{shippingCost} Ground Delivery:</span>
        <strong className="flex gap-1">
          {dateGround}
          <Tooltip title="Standard shipping orders ship USPS First Class or FedEx Ground. The delivery estimate window given is our best estimate and delivery times may vary.">
            <InfoCircleOutlined />
          </Tooltip>
        </strong>
      </p>
      {showExpress && (
        <p className="border-t border-[#D1D8D7] pt-2 flex justify-between">
          <span className="text-black/55">
            {`$${SHIPPING_EXPRESS_PRICE_DOLLARS}`} Express Delivery:
          </span>
          <strong className="flex gap-1">
            {dateExpress}
            <Tooltip title="This estimated date is based on FedEx's 2-Day delivery schedule.">
              <InfoCircleOutlined />
            </Tooltip>
          </strong>
        </p>
      )}
    </div>
  );
};
