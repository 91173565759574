import {
  FishingReport,
  Media,
  ProductFamily,
  ProductFamilySummary,
  ShopifyProduct,
  ShopifyVariant,
} from '@omniafishing/core';
import { Divider } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { NewBadge } from '../../components/new_badge/new_badge';
import { ProductDetailImages } from '../../components/product_detail_images/product_detail_images';
import { isItemNew, ProductTitle } from '../../components/product_variant_selector/product_title';
import productTitleStyles from '../../components/product_variant_selector/product_title.less';
import {
  ColorOption,
  ProductVariantSelector,
} from '../../components/product_variant_selector/product_variant_selector';
import { useResponsive } from '../../hooks/use_responsive';
import { AiAnglersSay } from './ai_anglers_say';
import styles from './product_detail_upper_section.less';

interface ProductDetailUpperSection {
  fishingReports: FishingReport[];
  media: Media[];
  openAndScrollToProductGridPanel: () => void;
  productFamily: ProductFamily;
  selectedVariant: ShopifyVariant;
  shopifyProduct: ShopifyProduct;
  productFamilySummary: ProductFamilySummary | null;
}

export const ProductDetailUpperSection = (props: ProductDetailUpperSection) => {
  const {
    fishingReports,
    media,
    openAndScrollToProductGridPanel,
    productFamily,
    selectedVariant,
    shopifyProduct,
    productFamilySummary,
  } = props;

  const { isDesktop } = useResponsive();
  const [hoveredColor, setHoveredColor] = useState<ColorOption>(null);

  const selectedProduct = useMemo(() => {
    return selectedVariant && productFamily
      ? productFamily.products.find((familyProduct) => familyProduct.sku === selectedVariant.sku)
      : null;
  }, [selectedVariant, productFamily]);

  const createdAtDate = selectedProduct?.created_at && dayjs(selectedProduct?.created_at);
  const isVariantNew = createdAtDate && isItemNew(createdAtDate);
  const isProductFamilyNew = isItemNew(dayjs(productFamily?.published_at));
  const hasAiSummary = productFamilySummary != null;

  return (
    <div className={styles.sectionWrapper}>
      <div className={styles.heroContainer}>
        <div className={styles.sticky}>
          <ProductDetailImages
            hoveredColor={hoveredColor}
            media={media}
            productFamily={productFamily}
            selectedVariant={selectedVariant}
            shopifyProduct={shopifyProduct}
          />
        </div>
      </div>
      <div className={styles.titleContainer}>
        <ProductTitle
          productFamily={productFamily}
          selectedProduct={selectedProduct}
          selectedVariant={selectedVariant}
          shopifyProduct={shopifyProduct}
          showOnlyTitle={true}
        />
        {hasAiSummary && (
          <AiAnglersSay
            fishingReportsCount={fishingReports.length}
            productFamilySummary={productFamilySummary}
          />
        )}

        {isDesktop && (
          <>
            <Divider />
          </>
        )}
      </div>
      <div className={styles.selectorContainer}>
        <>
          <p className={classNames(productTitleStyles.variantTitle, 'mb-3')}>
            <span>{selectedVariant.title}</span>
            {!isProductFamilyNew && isVariantNew && (
              <NewBadge className={productTitleStyles.variantNewBadge} />
            )}
          </p>
        </>

        <ProductVariantSelector
          openProductGrid={openAndScrollToProductGridPanel}
          onColorHover={setHoveredColor}
          selectedProduct={selectedProduct}
          selectedVariant={selectedVariant}
          shopifyProduct={shopifyProduct}
          productFamily={productFamily}
        />
      </div>
    </div>
  );
};
