import { FetchWaterbodyRecommendationsParams } from '@omniafishing/core';
import { apiV1 } from '../lib/api';

export const QueryKeys = {
  ME: {
    BASE: ['me'],
    WATERBODIES: ['me', 'waterbodies'],
    AMBASSADORS: ['me', 'ambassadors'],
    WAYPOINTS: {
      BASE: ['me', 'waypoints'],
      WITH_PARAMS: (params: Parameters<typeof apiV1.userWaypointsFetch>[0] = {}) => [
        ...QueryKeys.ME.WAYPOINTS.BASE,
        params,
      ],
    },
    WAYPOINT_FILTERS: ['me', 'waypoint_filters'],
  },
  REWARDS_PERCENTAGES: ['rewards_percentages'],
  WATERBODIES: {
    BASE: ['waterbodies'],
    BY_SLUG_OR_ID: (slug_or_id: string) => [...QueryKeys.WATERBODIES.BASE, slug_or_id],
    LAKE_BREAKDOWN_VIDEO: (
      slug_or_id: string,
      params: Parameters<typeof apiV1.waterbodyLakeBreakdownVideoFetch>[1]
    ) => [...QueryKeys.WATERBODIES.BY_SLUG_OR_ID(slug_or_id), 'lake_breakdown_video', params],
    RECOMMENDATIONS: (slug_or_id: string, params: FetchWaterbodyRecommendationsParams) => [
      ...QueryKeys.WATERBODIES.BY_SLUG_OR_ID(slug_or_id),
      'recommendations',
      params,
    ],
    TOP_SPECIES: (
      slug_or_id: string,
      params: Parameters<typeof apiV1.waterbodyTopSpeciesFetch>[1]
    ) => [...QueryKeys.WATERBODIES.BY_SLUG_OR_ID(slug_or_id), 'top_species', params],
    WATERBODY_TECHNIQUES: (
      slug_or_id: string,
      params: {
        species: string;
        season_group: string;
      }
    ) => [...QueryKeys.WATERBODIES.BY_SLUG_OR_ID(slug_or_id), 'waterbody_techniques', params],
    FISHING_REPORTS: (
      slug_or_id: string,
      params: Parameters<typeof apiV1.waterbodyFishingReportsFetch>[1]
    ) => [...QueryKeys.WATERBODIES.BY_SLUG_OR_ID(slug_or_id), 'fishing_reports', params],
    HOTBAIT_FISHING_REPORTS: (
      slug_or_id: string,
      params: Parameters<typeof apiV1.waterbodyHotbaitFishingReportsFetch>[1]
    ) => [...QueryKeys.WATERBODIES.BY_SLUG_OR_ID(slug_or_id), 'hotbait_fishing_reports', params],
  },
  SALES_PRO_PRODUCT_FAMILIES: ['sales_pro_product_families'],
  MAP: {
    BASE: ['map'],
    FISHING_REPORTS: (params: Parameters<typeof apiV1.mapFishingReportsFetch>[0]) => [
      ...QueryKeys.MAP.BASE,
      'fishing_reports',
      params,
    ],
  },
};
