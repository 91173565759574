import loadable from '@loadable/component';
import { Waterbody, WaterbodyDetail } from '@omniafishing/core';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MapRef, ViewState } from 'react-map-gl';
import { useDispatch, useSelector } from 'react-redux';
import { useDimensions } from '../../hooks/use_dimensions';
import { useQueryString } from '../../hooks/use_query_string';
import { useResponsive } from '../../hooks/use_responsive';
import { useUserWaterbodies } from '../../hooks/use_user_waterbodies';
import { apiV1 } from '../../lib/api';
import {
  getLatitude,
  getLongitude,
  getZoom,
  LocationActions,
} from '../../redux/geographic_location';
import { areBoundsZero } from '../../routes/map_page/lib/map_page_helpers';
import { ExtendedMapQueryParams } from '../../routes/map_page/lib/map_page_types';
import { MapLegend } from '../map/map_legend';
import { LakeDetailsModal } from '../map/map_modals/lake_details_modal/lake_details_modal';
import { BASE_LAYER, LAYER_TYPES, MapLayerState } from '../map/map_types';
import { FLYOVER_TRANSITION_DURATION, MapBounds } from '../map/omnia_map';
import { OmniaSwitch } from '../omnia_switch/omnia_switch';
import styles from './map_default.less';
import { useMapLayerState } from './use_map_layer_state';

const LoadableMap = loadable(
  () => import(/* webpackChunkName: "map-omnia_map" */ '../map/omnia_map')
);

export const MapQueryParams = {
  lng: 'lng',
  lat: 'lat',
  zoom: 'zoom',
  waterbody_slug: 'waterbody_slug',
} as const;
export type MapQueryParams = typeof MapQueryParams[keyof typeof MapQueryParams];

export interface MapDefaultProps {
  backToMapLink?: boolean;
  children?: React.ReactNode;
  className?: string;
  disablePinFetch?: boolean;
  disableScrollZoom?: boolean;
  featuredMarkers?: Waterbody[];
  geoCoderCentered?: boolean;
  geoCoderExpanded?: boolean;
  geoCoderFocusAndOpenOnMount?: boolean;
  hideGeocoder?: boolean;
  latitude?: number;
  longitude?: number;
  mapLayerStateOverrides?: Partial<MapLayerState>;
  maxBounds?: mapboxgl.LngLatBoundsLike;
  navControl?: boolean;
  navControlPosition?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  onBackToMapClick?: () => void;
  onGeocoderResultClick?: () => void;
  onLoad?: (bounds: MapBounds, mapRef: MapRef) => void;
  onMapClick?: (e: mapboxgl.MapMouseEvent) => void;
  onPinClick?: (waterbody: Waterbody) => void;
  onSatelliteChange?: () => void;
  restrictAllMovement?: boolean;
  placeHolderText?: string;
  replaceFileReportWithFollowButton?: boolean;
  showLegend?: boolean;
  showSatelliteToggle?: boolean;
  zoom?: number;
}

export const DESKTOP_PINS_LIMIT = 25;
export const MOBILE_PINS_LIMIT = 12;

export const MapDefault = (props: MapDefaultProps) => {
  const {
    backToMapLink,
    children,
    className,
    disablePinFetch,
    disableScrollZoom,
    featuredMarkers = [],
    geoCoderCentered,
    geoCoderExpanded,
    geoCoderFocusAndOpenOnMount,
    hideGeocoder,
    mapLayerStateOverrides,
    maxBounds,
    navControl = true,
    navControlPosition,
    onMapClick,
    onBackToMapClick,
    onGeocoderResultClick,
    onLoad,
    onPinClick,
    onSatelliteChange,
    placeHolderText,
    restrictAllMovement,
    replaceFileReportWithFollowButton,
    showLegend,
    showSatelliteToggle,
  } = props;

  const dispatch = useDispatch();
  const { isMobile } = useResponsive();
  const [markers, setMarkers] = useState<Waterbody[]>([]);
  const { userWaterbodies } = useUserWaterbodies();
  const [selectedWaterbody, setSelectedWaterbody] = useState<WaterbodyDetail>(null);

  const { getCurrentQuery, replaceQueryString, paramsString } = useQueryString();
  const currentQuery = getCurrentQuery<ExtendedMapQueryParams>();
  const currentParamWaterbodySlug = currentQuery.waterbody_slug;

  useEffect(() => {
    if (currentParamWaterbodySlug) {
      apiV1.waterbodyFetch(currentParamWaterbodySlug).then((res) => {
        setSelectedWaterbody(res.data.data);
      });
    } else {
      setSelectedWaterbody(null);
    }
  }, [currentParamWaterbodySlug]);

  const [measureRef, measureBounds] = useDimensions({
    onResize: true,
    onElementResize: true,
  });
  const [mapBounds, setMapBounds] = useState<MapBounds>({
    n: 0,
    e: 0,
    s: 0,
    w: 0,
  });

  const reduxLat = useSelector(getLatitude);
  const reduxLng = useSelector(getLongitude);
  const reduxZoom = useSelector(getZoom);
  const longitude = Number(currentQuery.lng) || props.longitude || reduxLng || -93.5519097201729;
  const latitude = Number(currentQuery.lat) || props.latitude || reduxLat || 44.9290887635944;
  const zoom = Number(currentQuery.zoom) || props.zoom || reduxZoom;
  const currentViewport = useMemo(() => {
    return {
      longitude,
      latitude,
      zoom,
    };
  }, [longitude, latitude, zoom]);

  const fetchPinsDisabled = useRef(false);

  const { setBaseLayer, mapLayerState, onSelectCoreLayer } =
    useMapLayerState(mapLayerStateOverrides);

  useEffect(() => {
    if (
      mapLayerStateOverrides != null &&
      !_.isEqual(mapLayerStateOverrides[LAYER_TYPES.CORE], mapLayerState[LAYER_TYPES.CORE])
    ) {
      onSelectCoreLayer(mapLayerStateOverrides[LAYER_TYPES.CORE]);
    }
    // This can be expanded to check all layer types, but for now its easier to just add them as needed
    // Weather and Points of Interest will be more complicated
  }, [mapLayerState, mapLayerStateOverrides]);

  const fetchLakes = (bounds: MapBounds) => {
    if (disablePinFetch || fetchPinsDisabled.current || !bounds || areBoundsZero(bounds)) {
      return;
    }

    const fetchParams = {
      ...bounds,
      limit: isMobile ? MOBILE_PINS_LIMIT : DESKTOP_PINS_LIMIT,
    };

    apiV1
      .mapPinsFetch(fetchParams)
      .then((response) => {
        setMarkers(response.data.data);
      })
      .catch((error) => {
        console.log(`ERROR: fetchPins`, error);
      });
  };

  const fetchLakesDebounced = useCallback(
    _.debounce((bounds: MapBounds) => fetchLakes(bounds), 300),
    [paramsString, isMobile, disablePinFetch, fetchPinsDisabled.current]
  );

  const onViewportChange = useCallback(
    (viewport: Partial<ViewState>, bounds: MapBounds) => {
      setMapBounds(bounds);
      updateLocationDebounced(viewport, bounds);
    },
    [paramsString]
  );

  const updateLocationDebounced = _.debounce((viewport: Partial<ViewState>, bounds: MapBounds) => {
    dispatch(LocationActions.LOCATION_UPDATE(viewport));
  }, 300);

  const onGeocoderResult = (result: any) => {
    // disabled pin loading during transition
    fetchPinsDisabled.current = true;
    setTimeout(() => {
      fetchPinsDisabled.current = false;
    }, FLYOVER_TRANSITION_DURATION - 100);

    if (result.value && !isMobile) {
      replaceQueryString<ExtendedMapQueryParams>({ waterbody_slug: result.value });
      onGeocoderResultClick?.();
    }
  };

  useEffect(() => {
    fetchLakesDebounced(mapBounds);
    // Use this to find/set lake bbox
    // const { e, n, s, w } = mapBounds;
    // console.log(
    //   'bbox',
    //   JSON.stringify([
    //     [w, s],
    //     [w, n],
    //     [e, n],
    //     [e, s],
    //     [w, s],
    //   ])
    // );
  }, [mapBounds.e, mapBounds.n, mapBounds.s, mapBounds.w]);

  return (
    <section className={classNames(styles.map, className)}>
      <div
        className={classNames(styles.wrapper, {
          [styles.wrapper__noSatelliteToggle]: !showSatelliteToggle,
          [styles.wrapper__noGeocoder]: hideGeocoder,
          [styles.wrapper__updatedMapControls]: false,
        })}
        ref={measureRef}
      >
        {measureBounds.width != null && (
          <LoadableMap
            backToMapLat={latitude}
            backToMapLink={backToMapLink}
            backToMapLng={longitude}
            backToMapZoom={zoom}
            disableScrollZoom={disableScrollZoom}
            featuredMarkers={featuredMarkers}
            geoCoder={!hideGeocoder}
            geoCoderCentered={geoCoderCentered}
            geoCoderExpanded={geoCoderExpanded && !selectedWaterbody}
            geoCoderFocusAndOpenOnMount={geoCoderFocusAndOpenOnMount}
            height={measureBounds.height}
            loadInteractiveImages={false}
            mapLayerState={mapLayerState}
            markers={markers}
            maxBounds={maxBounds}
            navControl={navControl}
            navControlPosition={navControlPosition}
            onBackToMapClick={onBackToMapClick}
            onGeocoderResult={onGeocoderResult}
            onPinClick={onPinClick}
            onLoad={(bounds, mapRef) => {
              setMapBounds(bounds);
              onLoad?.(bounds, mapRef);
            }}
            onMapClick={(e) => {
              onMapClick?.(e);
            }}
            onViewportChange={onViewportChange}
            placeHolderText={placeHolderText}
            restrictAllMovement={restrictAllMovement}
            selectedWaterbody={selectedWaterbody}
            userFavoriteWaterbodies={disablePinFetch ? [] : userWaterbodies}
            viewport={currentViewport}
            width={measureBounds.width}
          />
        )}
      </div>

      <div
        className={classNames(styles.lakeDetails, {
          [styles.lakeDetails__geoCoderCentered]: geoCoderCentered,
        })}
      >
        {selectedWaterbody && (
          <LakeDetailsModal
            onClose={() => {
              replaceQueryString<ExtendedMapQueryParams>({ waterbody_slug: null });
              setSelectedWaterbody(null);
            }}
            waterbody={selectedWaterbody}
            replaceFileReportWithFollowButton={replaceFileReportWithFollowButton}
            hideFileReportButton={false}
          />
        )}
      </div>

      {showLegend && (
        <div
          className={classNames(styles.legend, {
            [styles.legend__noGeocoder]: hideGeocoder,
          })}
        >
          <MapLegend />
        </div>
      )}

      {showSatelliteToggle && (
        <div className={styles.satelliteToggle}>
          <OmniaSwitch
            checked={mapLayerState[LAYER_TYPES.BASE] === BASE_LAYER.SATELLITE}
            onChange={(checked) => {
              setBaseLayer(checked ? BASE_LAYER.SATELLITE : BASE_LAYER.STANDARD);
              onSatelliteChange?.();
            }}
          >
            Satellite
          </OmniaSwitch>
        </div>
      )}

      {children}
    </section>
  );
};
