import { CheckCircleFilled, PlusOutlined } from '@ant-design/icons';
import {
  CartPromo,
  CartUpsellProduct,
  Category,
  DiscountApplication,
  isCartPromoCartPrice,
  LineItem,
  ShopifyGiftCard,
} from '@omniafishing/core';
import { Divider, Drawer, Input, Space } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import React, { Dispatch } from 'react';
import { Link } from 'react-router-dom';
import { getCategoryByProductType } from '../../categories_subcategories';
import { isDone, LoadingState } from '../../constants/loading_state';
import { apiV1 } from '../../lib/api';
import { isCartUpsellProductActive } from '../../lib/cart_upsell_product';
import { errorHandler } from '../../lib/error_handler';
import { roundToTwoDecimals } from '../../lib/numbers';
import { toDollars } from '../../lib/to_dollars';
import { OmniaUrls } from '../../lib/urls';
import { LineItemProduct, WebAnalytics } from '../../lib/web_analytics';
import {
  applyDiscount,
  applyUserCartRebate,
  CartActions,
  CartBanner,
  fetchCartUpsellProducts,
  fetchCheckout,
  removeCartGiftCard,
  removeDiscount,
  removeLineItemInCart,
  SHIPPING_FREE_CUTOFF,
  SHIPPING_GROUND_PRICE_CENTS,
  updateLineItems,
} from '../../redux/cart';
import { SignupLoginModalActions } from '../../redux/signup_login_modal';
import { RoutePaths } from '../../routes';
import { PRO_SIGNUP_LOGIN_MODAL_OPEN_PARAMS } from '../login_signup_modal/signup_login_modal';
import { OmniaButton } from '../omnia_button/omnia_button';
import SvgShipped from '../svg/shipped';
import styles from './cart.less';
import { CartContainsPromo, cartQualifiesForPromo } from './cart_contains_promo';
import { CartItem } from './cart_item';
import { CartPricePromo } from './cart_price_promo';

const lineItemIsGiftCard = (lineItem: LineItem) => {
  const title = lineItem.title.toLowerCase();
  return title.includes('gift') || title.includes('card');
};
const lineItemIsOnSaleOverThreshold = (lineItem: LineItem) => {
  return (
    Number(lineItem.variant.price.amount) / Number(lineItem.variant.compareAtPrice.amount) <= 0.8
  );
};
const lineItemIsCartPromo = (
  lineItem: LineItem,
  cartPricePromos: CartPromo[],
  cartPromoHandles: string[]
) => {
  if (!cartPromoHandles.includes(lineItem.variant.product.handle)) {
    return false;
  }
  const cartPromo = cartPricePromos.find(
    (p) => p.product_family.handle === lineItem.variant.product.handle
  );
  const itemPrices = cartPromo.cart_promos.map((p) => p.item_price);
  let price = Number(lineItem.variant.price.amount) * lineItem.quantity;
  const totalDiscounts = lineItem.discountAllocations.reduce((sum, allocation) => {
    return sum + Number(allocation.allocatedAmount.amount);
  }, 0);
  if (totalDiscounts > 0) {
    price = price - totalDiscounts;
  }
  // if an line item price matches the promo price, its has promo pricing
  return itemPrices.includes(price);
};
const splitLineItemsIntoSingleDoubleRewards = (lineItems: LineItem[], categories: Category[]) => {
  const singleRewardLineItems: LineItem[] = [];
  const doubleRewardLineItems: LineItem[] = [];
  lineItems.forEach((lineItem) => {
    if (
      getCategoryByProductType(categories, lineItem.variant.product.productType)?.name === 'apparel'
    ) {
      doubleRewardLineItems.push(lineItem);
    } else {
      singleRewardLineItems.push(lineItem);
    }
  });
  return [singleRewardLineItems, doubleRewardLineItems];
};

export const getLineItemsAdded = (previousLineItems: LineItem[], lineItems: LineItem[]) => {
  return lineItems.filter((li) => !previousLineItems.some((prevLi) => prevLi.id === li.id));
};
export const getLineItemsRemoved = (previousLineItems: LineItem[], lineItems: LineItem[]) => {
  return previousLineItems.filter((li) => !lineItems.some((prevLi) => prevLi.id === li.id));
};
export const getLineItemsQuantityChanged = (
  previousLineItems: LineItem[],
  lineItems: LineItem[]
) => {
  const existingLineItems = lineItems.filter((li) =>
    previousLineItems.some((prevLi) => prevLi.id === li.id)
  );
  const existingLineItemsWithQuantityChange = existingLineItems.filter((li) => {
    const previousLineItem = previousLineItems.find((prevLi) => prevLi.id === li.id);
    return previousLineItem.quantity !== li.quantity;
  });
  return existingLineItemsWithQuantityChange.map((li) => {
    const previousLineItem = previousLineItems.find((prevLi) => prevLi.id === li.id);
    return {
      line_item: li,
      difference: li.quantity - previousLineItem.quantity,
    };
  });
};

export const sumLineItemsSubtotal = (lineItems: LineItem[]) => {
  return lineItems.reduce((acc, lineItem) => {
    const lineSubtotal = parseFloat(lineItem.variant.price?.amount) * lineItem.quantity;
    const lineDiscount = lineItem.discountAllocations.reduce(
      (lineAcc, discount) => lineAcc + parseFloat(discount.allocatedAmount.amount),
      0
    );

    const lineTotal = lineSubtotal - lineDiscount;
    return acc + lineTotal;
  }, 0);
};

export const initialState = {
  discountCode: null as string,
  editingDiscount: false,
  inventories: {} as Record<string, number>,
};
type State = Readonly<typeof initialState>;

export interface CartProps {
  accessToken: string;
  activeDiscountCode: string;
  affiliateCampaign: string;
  affiliateSource: string;
  cartContainsPremium: boolean;
  cartHeaderBanners: CartBanner[];
  cartUpsellProducts: CartUpsellProduct[];
  cartUpsellProductsClosedSkus: string[];
  categories: Category[];
  checkoutId: string;
  discountErrorMessage: string;
  discounts: DiscountApplication[];
  dispatch: Dispatch<any>;
  double_rewards_percent: number;
  giftCards: ShopifyGiftCard[];
  hasDiscounts: boolean;
  hasGiftCards: boolean;
  hasRebateBalance: boolean;
  isMobile: boolean;
  isOpen: boolean;
  isPro: boolean;
  isUserPremium: boolean;
  isWebview: boolean;
  lineItems: LineItem[];
  lineItemsSubtotal: string;
  loadingState: LoadingState;
  pathname: string;
  promos: CartPromo[];
  rebateBalance: string;
  single_rewards_percent: number;
  subtotalPrice: string;
  webUrl: string;
}

export class Cart extends React.Component<CartProps, State> {
  state = { ...initialState };
  parent = React.createRef<HTMLElement>();

  componentDidMount() {
    const { checkoutId, dispatch } = this.props;
    if (checkoutId) {
      dispatch(fetchCheckout(checkoutId));
    }
  }

  componentDidUpdate(prevProps: CartProps) {
    const {
      isOpen,
      lineItems,
      subtotalPrice,
      hasDiscounts,
      activeDiscountCode,
      hasGiftCards,
      checkoutId,
      loadingState,
      dispatch,
    } = this.props;
    if (!prevProps.isOpen && isOpen) {
      const lineItemsQuantity = lineItems.reduce((acc, lineItem) => acc + lineItem.quantity, 0);
      WebAnalytics.cartViewed(parseFloat(subtotalPrice), lineItems.length, lineItemsQuantity);
    }
    if (!prevProps.hasDiscounts && hasDiscounts) {
      WebAnalytics.cartPromoAdded({
        code: activeDiscountCode,
        cart_discount_amount: this.Discount,
        ...this.WebAnalyticsCartEventArgs,
      });
    }

    if (prevProps.hasDiscounts && !hasDiscounts) {
      WebAnalytics.cartPromoRemoved({
        code: prevProps.activeDiscountCode,
        cart_discount_amount: this.Discount,
        ...this.WebAnalyticsCartEventArgs,
      });
    }

    if (!prevProps.hasGiftCards && hasGiftCards) {
      WebAnalytics.cartRewardsAdded({
        cart_rewards_amount: roundToTwoDecimals(this.GiftCardsAppliedInCents / 100),
        ...this.WebAnalyticsCartEventArgs,
      });
    }

    if (prevProps.hasGiftCards && !hasGiftCards) {
      WebAnalytics.cartRewardsRemoved({
        cart_rewards_amount: roundToTwoDecimals(this.GiftCardsAppliedInCents / 100),
        ...this.WebAnalyticsCartEventArgs,
      });
    }

    const cartSubtotal = parseFloat(subtotalPrice);
    const currentLineItemProducts: LineItemProduct[] = lineItems.map((li) => {
      return {
        id: li.variant.sku,
        sku: li.variant.sku,
        name: `${li.variant.product.title} ${li.variant.title}`,
        price: Number(li.variant.price.amount),
        quantity: li.quantity,
        url:
          window.location.origin +
          OmniaUrls.product(li.variant.product.handle, li.variant.selectedOptions),
        imageUrl: li.variant.image.url,
      };
    });

    if (
      !_.isEqual(prevProps.lineItems, lineItems) &&
      isDone(prevProps.loadingState) &&
      isDone(loadingState)
    ) {
      const lineItemsAdded = getLineItemsAdded(prevProps.lineItems, lineItems);
      lineItemsAdded.forEach((line_item) => {
        WebAnalytics.productAdded({
          productOrVariant: line_item.variant,
          quantity: line_item.quantity,
          cart_id: checkoutId,
          coupon: activeDiscountCode,
          position: null,
          total: cartSubtotal,
          products: currentLineItemProducts,
        });
      });

      const lineItemsRemoved = getLineItemsRemoved(prevProps.lineItems, lineItems);
      lineItemsRemoved.forEach((line_item) => {
        WebAnalytics.productRemoved({
          variant: line_item.variant,
          quantity: line_item.quantity,
          cart_id: checkoutId,
          coupon: activeDiscountCode,
          total: cartSubtotal,
          products: currentLineItemProducts,
        });
      });

      const lineItemsWithQuantityChange = getLineItemsQuantityChanged(
        prevProps.lineItems,
        lineItems
      );
      lineItemsWithQuantityChange.forEach(({ line_item, difference }) => {
        if (difference > 0) {
          WebAnalytics.productAdded({
            productOrVariant: line_item.variant,
            quantity: Math.abs(difference),
            cart_id: checkoutId,
            coupon: activeDiscountCode,
            position: null,
            total: cartSubtotal,
            products: currentLineItemProducts,
          });
        } else {
          WebAnalytics.productRemoved({
            variant: line_item.variant,
            quantity: Math.abs(difference),
            cart_id: checkoutId,
            coupon: activeDiscountCode,
            total: cartSubtotal,
            products: currentLineItemProducts,
          });
        }
      });
    }

    const prevLineItemSkus = prevProps.lineItems.map((lineItem) => lineItem.variant.sku);
    const lineItemSkus = lineItems.map((lineItem) => lineItem.variant.sku);
    if (!_.isEqual(prevLineItemSkus, lineItemSkus) && lineItemSkus.length) {
      dispatch(fetchCartUpsellProducts(lineItemSkus));
      apiV1.inventoryFetch(lineItemSkus).then((res) => {
        this.setState({
          inventories: res.data.data,
        });
      });
    }
  }

  onClose = () => {
    const { dispatch } = this.props;
    const { editingDiscount, discountCode } = initialState;
    this.setState({
      editingDiscount,
      discountCode,
    });
    dispatch(CartActions.CART_CLOSE());
  };

  onCheckoutClick = () => {
    const { webUrl, checkoutId, affiliateCampaign, affiliateSource } = this.props;

    apiV1
      .checkoutUrlGenerate({
        checkout_url: webUrl,
        checkout_id: checkoutId,
        campaign_source: affiliateSource,
        campaign: affiliateCampaign,
      })
      .then((response) => {
        window.location.assign(response.data.data);
      })
      .catch((error) => {
        errorHandler(`Error: cart/checkout`, error);
      });
  };

  removeLineItem = (line_item: LineItem) => () => {
    const { checkoutId, dispatch } = this.props;

    dispatch(removeLineItemInCart(checkoutId, [line_item.id]));
  };

  onQuantityChange = (line_item: LineItem) => (val: number | string) => {
    const { dispatch, checkoutId } = this.props;

    if (val === null) {
      return;
    }
    const quantity = Number(val);
    const lineItemUpdate = {
      id: line_item.id,
      quantity,
    };
    dispatch(updateLineItems(checkoutId, [lineItemUpdate]));
  };

  onDiscountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ discountCode: e.target.value });
  };

  applyDiscount = () => {
    const { checkoutId, dispatch, isUserPremium } = this.props;
    if (!this.state.discountCode) {
      return;
    }
    if (
      (this.state.discountCode === 'EARLYBFPREMIUM' ||
        this.state.discountCode === 'PREMIUMEARLYBF') &&
      !isUserPremium
    ) {
      return;
    }
    dispatch(applyDiscount(checkoutId, this.state.discountCode));
  };

  editDiscount = () => {
    this.setState({ editingDiscount: true });
  };

  removeDiscount = () => {
    const { checkoutId, dispatch } = this.props;
    const { editingDiscount, discountCode } = initialState;
    this.setState({
      editingDiscount,
      discountCode,
    });
    dispatch(removeDiscount(checkoutId));
  };

  applyRebate = () => {
    const { dispatch } = this.props;
    dispatch(applyUserCartRebate());
  };

  removeRebate = () => {
    const { giftCards, dispatch, checkoutId } = this.props;
    const giftCardIds = giftCards.map((gc) => gc.id);
    dispatch(removeCartGiftCard(checkoutId, giftCardIds));
  };

  get WebAnalyticsCartEventArgs() {
    const { lineItems, subtotalPrice } = this.props;
    return {
      cart_total_amount: parseFloat(subtotalPrice),
      cart_total_items: lineItems.length,
      cart_total_quantity: lineItems.reduce((acc, li) => acc + li.quantity, 0),
    };
  }

  get HasPremiumMembership() {
    const { isUserPremium, cartContainsPremium } = this.props;
    return cartContainsPremium || isUserPremium;
  }

  get ShippingAmountCents() {
    const { subtotalPrice } = this.props;
    if (this.HasPremiumMembership) {
      return 0;
    }
    const subtotal = parseFloat(subtotalPrice);
    if (subtotal >= SHIPPING_FREE_CUTOFF) {
      return 0;
    }
    return SHIPPING_GROUND_PRICE_CENTS;
  }

  get ShippingCost() {
    const cents = this.ShippingAmountCents;
    if (cents === 0) {
      return 'FREE';
    }
    return toDollars(cents / 100);
  }

  get ToFreeShipping() {
    const { subtotalPrice } = this.props;
    const subtotal = parseFloat(subtotalPrice);
    return Number((SHIPPING_FREE_CUTOFF - subtotal).toFixed(2));
  }

  get RewardsAmount() {
    const { lineItems, promos, categories } = this.props;

    const cartPricePromos = promos.filter((p) => isCartPromoCartPrice(p));
    const cartPromoHandles = cartPricePromos.map((p) => p.product_family.handle);

    const rewardableLineItems = lineItems
      .filter((li) => !lineItemIsGiftCard(li))
      .filter((li) => !lineItemIsOnSaleOverThreshold(li))
      .filter((li) => !lineItemIsCartPromo(li, cartPricePromos, cartPromoHandles));

    const [singleRewardLineItems, doubleRewardLineItems] = splitLineItemsIntoSingleDoubleRewards(
      rewardableLineItems,
      categories
    );

    let singleRewardLineItemsSubtotal = sumLineItemsSubtotal(singleRewardLineItems);
    let doubleRewardLineItemsSubtotal = sumLineItemsSubtotal(doubleRewardLineItems);

    const giftCardsAppliedInDollars = this.GiftCardsAppliedInCents / 100;

    if (doubleRewardLineItemsSubtotal > 0) {
      doubleRewardLineItemsSubtotal -= giftCardsAppliedInDollars;
      if (doubleRewardLineItemsSubtotal < 0) {
        // adding because doubleRewardLineItemsSubtotal is a negative number
        singleRewardLineItemsSubtotal += doubleRewardLineItemsSubtotal;
        doubleRewardLineItemsSubtotal = 0;
      }
    } else {
      singleRewardLineItemsSubtotal -= giftCardsAppliedInDollars;
    }

    const rewardEarned =
      singleRewardLineItemsSubtotal * this.props.single_rewards_percent +
      doubleRewardLineItemsSubtotal * this.props.double_rewards_percent;

    return rewardEarned;
  }

  get CartTotal() {
    const { subtotalPrice } = this.props;
    const subtotalInCents = parseFloat(subtotalPrice) * 100;
    const totalInDollars =
      (subtotalInCents + this.ShippingAmountCents - this.GiftCardsAppliedInCents) / 100;
    return toDollars(totalInDollars);
  }

  get Discount() {
    const { lineItems } = this.props;
    const discountTotal = lineItems.reduce((acc, lineItem) => {
      const lineItemDiscount = lineItem.discountAllocations.reduce((lineAcc, discount) => {
        return lineAcc + parseFloat(discount.allocatedAmount.amount);
      }, 0);
      return acc + lineItemDiscount;
    }, 0);
    return discountTotal;
  }

  get GiftCardsAppliedInCents() {
    const { giftCards } = this.props;

    const giftCardTotal = giftCards.reduce(
      (acc, giftCard) => acc + Number(giftCard.amountUsed.amount),
      0
    );

    return giftCardTotal * 100;
  }

  get GiftCardsApplied() {
    return toDollars(this.GiftCardsAppliedInCents / 100);
  }

  get PromoCodeSection() {
    const { discountErrorMessage, activeDiscountCode } = this.props;

    let content = (
      <OmniaButton kind="link" size="lg" fontSize={14} onPress={this.editDiscount}>
        <PlusOutlined /> Add Promo Code
      </OmniaButton>
    );

    if (this.state.editingDiscount) {
      content = (
        <>
          <Input.Group compact>
            <Input
              onChange={this.onDiscountChange}
              placeholder="Promo Code"
              autoFocus
              style={{ width: '60%' }}
            />
            <OmniaButton
              kind="tertiary"
              size="md"
              fontSize={14}
              style={{ boxShadow: 'none', height: '32px' }}
              onPress={this.applyDiscount}
            >
              Apply
            </OmniaButton>
          </Input.Group>
          {discountErrorMessage && (
            <span className={styles.errorMessage}>{discountErrorMessage}</span>
          )}
        </>
      );
    }

    if (activeDiscountCode) {
      content = (
        <span className={styles.promoCode}>
          <span>
            Promo Code: <strong>{activeDiscountCode}</strong>
          </span>
          <button
            className={classNames(styles.buttonAsLink, styles.removePromo)}
            onClick={this.removeDiscount}
          >
            Remove
          </button>
        </span>
      );
    }

    return <div className={styles.section}>{content}</div>;
  }

  get PremiumUpsell() {
    const { hasRebateBalance, hasGiftCards, rebateBalance, discounts, dispatch, isPro } =
      this.props;
    const discountOverThreshold =
      discounts[0] &&
      discounts[0].value.__typename === 'PricingPercentageValue' &&
      discounts[0].value.percentage >= 20.0;

    return (
      <div className={styles.section}>
        <div>
          <p className={styles.sectionHeading}>PRO Credits</p>

          <div className={styles.premiumImg}>
            <svg width="95" height="114" viewBox="0 0 95 114" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M65.571.084H29.719C13.705.084.722 13.067.722 29.082v28.319l26.434-16.118V29.55a7.556 7.556 0 017.556-7.557H60.58a7.56 7.56 0 017.56 7.559v54.259a7.557 7.557 0 01-7.557 7.558H34.241c-11.04 0-9.133-11.467-5.579-16.369l7.598 3.93c-1.645-9.661 1.026-27.941 4.756-38.656C18.343 55.741.722 68.054.722 88.323c0 12.639 9.51 24.955 26.454 24.955h38.399c16.016 0 28.998-12.983 28.998-28.998V29.086C94.573 13.068 81.589.084 65.571.084"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>
          </div>
          <div className={styles.premiumText}>
            {hasRebateBalance && (
              <>
                <div>
                  Available Omnia PRO Credits: <strong>{toDollars(rebateBalance)}</strong>
                </div>
                {hasGiftCards ? (
                  <button
                    className={classNames(styles.buttonAsLink, styles.rebateButton)}
                    onClick={this.removeRebate}
                  >
                    Remove Omnia PRO Credits
                  </button>
                ) : (
                  <button
                    className={classNames(styles.buttonAsLink, styles.rebateButton)}
                    onClick={this.applyRebate}
                  >
                    Apply To This Order
                  </button>
                )}
              </>
            )}

            {this.HasPremiumMembership ? (
              <>
                {discountOverThreshold ? (
                  <div style={{ fontStyle: 'italic' }}>
                    <p>
                      This order is not eligible to earn Omnia PRO Credits. PRO credits are not
                      available when a 20% off or greater promo code is used.
                      <br />
                      <Link to={RoutePaths.PREMIUM_PRO}>Learn More</Link>
                    </p>
                  </div>
                ) : (
                  <div>
                    <span className={styles.rewards}>
                      Omnia PRO Credits earned for this order:{' '}
                      <strong>{toDollars(this.RewardsAmount)}</strong>
                    </span>
                  </div>
                )}
              </>
            ) : (
              <div className={styles.premiumBlurb}>
                <p>
                  {this.RewardsAmount > 0 && !discountOverThreshold && (
                    <>
                      <strong>Earn {toDollars(this.RewardsAmount)} credit</strong>
                      <br />
                    </>
                  )}
                  You could be earning 10% back &amp; getting FREE SHIPPING on every order (no
                  minimums) as an Omnia PRO Member.
                </p>
                <div className={styles.premiumActions}>
                  <OmniaButton
                    onPress={() => {
                      dispatch(CartActions.CART_CLOSE());
                      dispatch(
                        SignupLoginModalActions.SIGNUP_LOGIN_MODAL_OPEN(
                          PRO_SIGNUP_LOGIN_MODAL_OPEN_PARAMS
                        )
                      );
                    }}
                    kind="primary"
                    size="md"
                    isDisabled={isPro}
                  >
                    Join Omnia PRO
                  </OmniaButton>
                  <Link
                    to={RoutePaths.PREMIUM_PRO}
                    onClick={() => {
                      this.onClose();
                      WebAnalytics.cartClick(
                        '[cart].(premium_learn_more)',
                        this.WebAnalyticsCartEventArgs
                      );
                    }}
                  >
                    Learn more
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  get Subtotal() {
    return toDollars(this.props.lineItemsSubtotal);
  }

  get Summary() {
    const { lineItems, hasDiscounts, hasGiftCards } = this.props;
    if (lineItems.length === 0) {
      return null;
    }

    return (
      <div className={styles.section}>
        <div>
          <p className={styles.sectionHeading}>Order Summary</p>
          <table className={styles.table}>
            <tbody>
              <tr>
                <td>Subtotal:</td>
                <td>{this.Subtotal}</td>
              </tr>
              {hasDiscounts && (
                <tr>
                  <td>Discounts:</td>
                  <td>-{toDollars(this.Discount)}</td>
                </tr>
              )}
              <tr>
                <td>Shipping:</td>
                <td>{this.ShippingCost}</td>
              </tr>
              <tr>
                <td>Sales Tax:</td>
                <td>See at checkout</td>
              </tr>
              {hasGiftCards && (
                <tr>
                  <td>PRO Credits:</td>
                  <td>-{this.GiftCardsApplied}</td>
                </tr>
              )}
              <tr>
                <td>
                  <strong>Total:</strong>
                </td>
                <td>
                  <strong>{this.CartTotal}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  get getDrawerContainer() {
    return this.parent.current || 'body';
  }

  get cartHeaderBanners() {
    const { cartHeaderBanners } = this.props;
    if (cartHeaderBanners?.length) {
      return cartHeaderBanners.map((banner) => {
        // const showRemoveKitButton = banner?.type === 'kit_remove';
        return (
          <React.Fragment key={banner.message}>
            <div className={styles.cartHeaderBanner}>
              <div className={styles.cartHeaderContent}>
                <CheckCircleFilled />
                <p>{banner.message}</p>
              </div>
            </div>
            {/* {showRemoveKitButton && (
              <OmniaButton
                kind="link"
                size="lg"
                onPress={() => {
                  UPDATE THIS TO REMOVE KIT NOT ENTIRE CART
                  dispatch(
                    removeLineItemInCart(
                      checkoutId,
                      lineItems.map((lineItem) => lineItem.id)
                    )
                  );
                }}
              >
                Remove Kit 'get and add kit item name here'
              </OmniaButton>
            )} */}
          </React.Fragment>
        );
      });
    }
  }

  render() {
    const {
      isOpen,
      lineItems,
      promos,
      isUserPremium,
      isMobile,
      isWebview,
      cartUpsellProducts,
      cartUpsellProductsClosedSkus,
      webUrl,
      checkoutId,
    } = this.props;
    const { inventories } = this.state;

    const lineItemSkus = lineItems.map((lineItem) => lineItem.variant.sku);
    const promosToShow = promos
      .filter((promo) => (promo.premium_only && isUserPremium) || !promo.premium_only)
      .filter((promo) => {
        const productFamilySkus = promo.product_family.products.map((p) => p.sku);
        const cartDoesNotContainPromoProduct =
          _.intersection(lineItemSkus, productFamilySkus).length === 0;
        if (isCartPromoCartPrice(promo)) {
          return cartDoesNotContainPromoProduct;
        } else {
          if (promo.hide_from_cart) {
            return cartQualifiesForPromo(promo, lineItems) && cartDoesNotContainPromoProduct;
          } else {
            return !cartQualifiesForPromo(promo, lineItems) || cartDoesNotContainPromoProduct;
          }
        }
      });

    const activeCartUpsellProducts = cartUpsellProducts.filter((cartUpsellProduct) =>
      isCartUpsellProductActive(cartUpsellProduct, new Date())
    );

    const lineItemHandles = lineItems.map((li) => li.variant.product.handle);
    const lineItemsWithUnaddedUnclosedCartUpsells = lineItems.filter((li) =>
      activeCartUpsellProducts.some(
        (cup) =>
          li.variant.product.handle === cup.item_in_cart.handle &&
          !lineItemHandles.includes(cup.item_to_recommend.shopify_product_handle) &&
          !cartUpsellProductsClosedSkus.includes(cup.item_to_recommend.sku)
      )
    );

    const promosSection =
      promosToShow.length > 0 ? (
        <div className={styles.section}>
          <p className={styles.sectionHeading}>Available Deals</p>
          <div className={styles.sectionBreakout}>
            <Space direction="vertical" split={<Divider />} className={styles.promosSpace}>
              {promosToShow.map((promo) => {
                if (isCartPromoCartPrice(promo)) {
                  return <CartPricePromo promo={promo} key={promo.product_family.handle} />;
                } else {
                  return <CartContainsPromo promo={promo} key={promo.product_family.handle} />;
                }
              })}
            </Space>
          </div>
        </div>
      ) : null;

    const shippingSection = (
      <div className={styles.section}>
        <div className={styles.shipWrapper}>
          <SvgShipped height="2.2em" width="2.2em" className={styles.shipIcon} />
          <div>
            <p className={styles.sectionHeading}>Shipping</p>
            <p className={styles.shipFast}>
              <strong>We ship fast!</strong>
              <br />
              Orders placed before 1pm Central ship the same&nbsp;day.
            </p>
            <p>
              $5 Ground, FREE for orders over $50
              <br />
              $6 2-day Shipping (excludes rods &amp; tackle boxes)
            </p>
            {!this.HasPremiumMembership && (
              <div>
                {this.ToFreeShipping > 0 ? (
                  <p>
                    Spend <strong>${this.ToFreeShipping}</strong> more and get{' '}
                    <strong>FREE SHIPPING</strong>
                  </p>
                ) : (
                  <p>
                    This order qualifies for <strong>FREE SHIPPING</strong>
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );

    return (
      <section ref={this.parent}>
        <Drawer
          title={null}
          placement="right"
          getContainer={this.getDrawerContainer}
          onClose={this.onClose}
          open={!isWebview && isOpen}
          width={isMobile ? 375 : 420}
          className={styles.drawer}
        >
          <div className={styles.cart}>
            <div className={styles.header}>
              {lineItems.length > 0 ? (
                <>
                  <p className={classNames(styles.sectionHeading, styles.headerHeading)}>
                    {this.Subtotal} Subtotal
                  </p>
                  <p className={styles.itemsCount}>
                    {lineItems.reduce((sum, li) => sum + li.quantity, 0)} items
                  </p>
                  {this.cartHeaderBanners}
                </>
              ) : (
                <p className={classNames(styles.sectionHeading, styles.headerHeading)}>
                  Your Cart is Empty
                </p>
              )}
            </div>
            <div className={styles.body}>
              {lineItems.length > 0 ? (
                <>
                  <div className={styles.lineItems}>
                    {lineItems.map((lineItem) => {
                      return (
                        <CartItem
                          key={lineItem.id}
                          lineItem={lineItem}
                          inventory={inventories[lineItem.variant.sku]}
                          onQuantityChange={this.onQuantityChange(lineItem)}
                          removeLineItem={this.removeLineItem(lineItem)}
                          className={styles.lineItem}
                          // only the most recently added upsell match should be shown
                          showUpsell={
                            lineItem.id ===
                            lineItemsWithUnaddedUnclosedCartUpsells[
                              lineItemsWithUnaddedUnclosedCartUpsells.length - 1
                            ]?.id
                          }
                        />
                      );
                    })}
                  </div>

                  {this.PromoCodeSection}
                  {shippingSection}
                  {this.Summary}
                  {this.PremiumUpsell}
                  {promosSection}
                </>
              ) : (
                <>
                  {promosSection}
                  {this.PremiumUpsell}
                  {this.PromoCodeSection}
                  {shippingSection}
                </>
              )}
            </div>
            <div className={styles.footer}>
              <OmniaButton
                onClick={this.onCheckoutClick}
                kind="primary"
                size="lg"
                isDisabled={lineItems.length === 0 || !webUrl || !checkoutId}
              >
                Check Out
              </OmniaButton>
            </div>
          </div>
        </Drawer>
      </section>
    );
  }
}
