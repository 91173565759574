import { CloseOutlined, RightOutlined } from '@ant-design/icons';
import { ProductFamilySummary } from '@omniafishing/core';
import pluralize from 'pluralize';
import React from 'react';
import AnimateHeight from 'react-animate-height';
import { Statsig, useExperiment } from 'statsig-react';
import { OmniaButton } from '../../components/omnia_button/omnia_button';
import { useQueryString } from '../../hooks/use_query_string';
import { useResponsive } from '../../hooks/use_responsive';
import { GlobalQueryParams } from '../../lib/query_string';
import { WebAnalytics } from '../../lib/web_analytics';
import aiLogo from './omnia_ai_logo.png';

interface FishingReportSummaryProps {
  fishingReportsCount: number;
  productFamilySummary: ProductFamilySummary;
}

interface ProductFamilySummaryWithUserImgs extends ProductFamilySummary {
  user_img_urls: string[];
}

export const AiAnglersSay = (props: FishingReportSummaryProps) => {
  const { fishingReportsCount, productFamilySummary } = props;

  const { isDesktop, isMobile } = useResponsive();
  const { replaceQueryString, getCurrentQuery } = useQueryString();
  const [selectedAttributeName, setSelectedAttributeName] = React.useState<string | null>(null);
  const [height, setHeight] = React.useState<0 | 'auto'>(0);
  const [summaryExpanded, setSummaryExpanded] = React.useState<boolean>(isDesktop);
  const truncatedView = isMobile && !summaryExpanded;

  const currentQuery = getCurrentQuery<{ ai_content: 'angler_says' | 'at_a_glance' }>();
  const queryContent = currentQuery.ai_content;
  const llmContent = useExperiment('angler_says_vs_at_a_glance').config.get<
    'angler_says' | 'at_a_glance'
  >('content', 'angler_says');
  const title = useExperiment('pdp_ai_summary_title').config.get<
    'Anglers Say' | 'Fishing Reports Summary'
  >('content', 'Anglers Say');

  const showUserImgs = useExperiment('pdp_summary_user_imgs').config.get<boolean>(
    'show_imgs',
    false
  );

  if (
    queryContent === 'at_a_glance' ||
    !(llmContent === 'angler_says' || queryContent === 'angler_says')
  ) {
    return null;
  }

  const { summary, themes, user_img_urls } =
    productFamilySummary as ProductFamilySummaryWithUserImgs;
  const selectedAttribute = themes.find((t) => t.name === selectedAttributeName);

  return (
    <div
      className={`relative border rounded-xl p-4 bg-[#f4f4f4] ${
        truncatedView && 'h-[220px] overflow-hidden'
      }`}
    >
      <div className="flex gap-3 mb-2">
        <p className=" font-bold font-termina text-[#002BFF] leading-tight">
          {title}:{' '}
          <span className="block text-[11px] font-normal font-sans">
            From {fishingReportsCount || ''} Fishing {pluralize('Report', fishingReportsCount)}
          </span>
        </p>
        {showUserImgs && (
          <div className="flex items-center">
            {user_img_urls.map((img_url, i) => {
              return (
                <div
                  className="-mr-4 rounded-full border-2 border-[#f4f4f4] relative"
                  style={{ zIndex: user_img_urls.length - i }}
                  key={img_url}
                >
                  <img src={img_url} alt="User Image" width={26} height={26} />
                </div>
              );
            })}
          </div>
        )}
      </div>

      <p className={`font-[15px] mb-6`}>{summary}</p>

      <p className="font-bold mb-2 font-termina text-[11px] flex items-center gap-2">
        Select to learn more{' '}
        <span className="bg-gradient-to-r from-[#002BFF] to-[#FF2A00] h-1 flex-1" />
      </p>

      <ol
        className={`flex gap-2 mb-3 items-start ${isMobile && 'flex-col'} ${
          isDesktop && 'flex-row flex-wrap'
        }`}
      >
        {themes.map((theme) => {
          const selected = selectedAttributeName === theme.name && height === 'auto';
          return (
            <li
              key={theme.name}
              className={`rounded-full overflow-hidden p-1 border-1 border-black/15 shadow-sm select-none bg-white cursor-pointer text-[13px] bg-clip-padding hover:bg-sky-50 hover:-translate-y-0.5 hover:shadow-md transition-all ${
                selected ? 'bg-gradient-to-r from-[#002BFF] to-[#FF2A00] border-transparent' : ''
              }`}
              onClick={() => {
                if (selected) {
                  setHeight(0);
                } else {
                  setHeight('auto');
                }
                setSelectedAttributeName(theme.name);
                WebAnalytics.productDetailPageAiSummaryThemeClicked();
                Statsig.logEvent('Product Details Page AI Summary Theme Clicked');
              }}
            >
              <span
                className={`py-1 px-4 rounded-full font-bold flex gap-2 ${
                  selected ? 'bg-black text-white' : ''
                }`}
              >
                {theme.name}
                <RightOutlined rotate={selected ? 90 : 0} />
              </span>
            </li>
          );
        })}
      </ol>
      <AnimateHeight height={height} easing="ease-out" duration={200}>
        <div className="bg-gradient-to-b from-[#002BFF] to-[#FF2A00] rounded-xl p-1">
          {selectedAttribute && (
            <div className={`p-3 relative rounded-lg bg-white`}>
              <p className="flex gap-1 mb-2 mr-7">
                <span className="text-[11px]">
                  <strong className="block font-termina text-[15px]">
                    {selectedAttribute.name}
                  </strong>
                </span>
              </p>
              <p className="mb-2">{selectedAttribute.summary}</p>
              <ul className="flex flex-col gap-3">
                {selectedAttribute.evidence.map((e) => {
                  return (
                    <li key={e.source_fishing_report_id} className="roudned-xl">
                      <q className="quotes-none block p-3 bg-[#E9EEEC] text-[#061622]/65 rounded-xl before:content-['“'] before:font-termina before:font-bold before:block before:text-[#8A9397] before:leading-none">
                        {e.excerpt}{' '}
                        <span
                          className="text-[#002BFF] cursor-pointer"
                          onClick={() => {
                            WebAnalytics.fishingReportReadMoreClicked(e.source_fishing_report_id);
                            replaceQueryString({
                              [GlobalQueryParams.report_id]: e.source_fishing_report_id,
                            });
                          }}
                        >
                          Read&nbsp;report
                        </span>
                      </q>
                    </li>
                  );
                })}
              </ul>
              <OmniaButton
                size="sm"
                shape="circle"
                kind="tertiary"
                className="absolute top-3 right-3"
                onClick={() => {
                  setHeight(0);
                }}
              >
                <CloseOutlined className="flex-grow-0 flex-shrink-0" />
              </OmniaButton>
            </div>
          )}
        </div>
      </AnimateHeight>

      <div className="flex gap-3 items-center">
        <img src={aiLogo} alt="Omnia AI" width={50} className="hidden sm:block" />
        <span className="text-[11px] text-[#061622] font-bold">Powered by Omnia AI</span>
      </div>

      {truncatedView && (
        <div className="absolute bottom-0 left-0 right-0 block p-3 pt-20 z-10 bg-gradient-to-t from-[#f4f4f4] from-50% to-[transparent]">
          <OmniaButton
            kind="tertiary"
            size="md"
            block
            onClick={() => {
              WebAnalytics.productDetailPageAiSummaryReadMoreClicked();
              Statsig.logEvent('Product Details Page AI Summary Read More Clicked');
              setSummaryExpanded(true);
            }}
          >
            Read More
          </OmniaButton>
        </div>
      )}
    </div>
  );
};
