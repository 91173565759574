import {
  FishingReport,
  Product,
  ProductFamily,
  ShopifyProduct,
  WaterbodyDetail,
} from '@omniafishing/core';
import classNames from 'classnames';
import React, { useRef } from 'react';
import AnimateHeight from 'react-animate-height';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useExperiment } from 'statsig-react';
import { useQueryParamLineItemAttribution } from '../../hooks/use_query_param_lineItem_attribution';
import { useResponsive } from '../../hooks/use_responsive';
import { AlgoliaEvents } from '../../lib/algolia_events';
import { FacebookEvents } from '../../lib/facebook_events';
import { fishingReportContainsSku } from '../../lib/fishing_reports';
import { GoogleEvents } from '../../lib/google_events';
import { getLineItemCustomAttributes } from '../../lib/line_item_attributes';
import { WebAnalytics, WebAnalyticsEventAreas } from '../../lib/web_analytics';
import { addLineItems, getCheckoutId, LineItemToAdd } from '../../redux/cart';
import { InventoryModalActions } from '../../redux/inventory_modal';
import { getUser } from '../../redux/user';
import { OmniaButton } from '../omnia_button/omnia_button';
import styles from './product_detail_grid.less';
import { ProductRow } from './product_row';

interface ProductDetailGridProps {
  fishingReports: FishingReport[];
  isPanelOpen?: boolean;
  onViewFishingReport?: (val: string) => void;
  openAndScrollToFishingReportsPanel?: () => void;
  productFamily: ProductFamily;
  shopifyProduct: ShopifyProduct | null;
  waterbody?: WaterbodyDetail;
}

export const ProductDetailGrid = React.memo((props: ProductDetailGridProps) => {
  const {
    fishingReports,
    onViewFishingReport,
    openAndScrollToFishingReportsPanel,
    productFamily,
    shopifyProduct,
  } = props;

  const products = productFamily.products;
  const containerRef = useRef<HTMLDivElement>(null);
  const user = useSelector(getUser);
  const location = useLocation();
  const checkoutId = useSelector(getCheckoutId);
  const dispatch = useDispatch();
  const lineItemAttribution = useQueryParamLineItemAttribution();
  const { isMobile } = useResponsive();

  const truncateVariations = useExperiment('pdp_variations_truncated').config.get<boolean>(
    'truncate_variations',
    false
  );
  const trimVariations = truncateVariations && products.length > 3;
  const [height, setHeight] = React.useState<number | 'auto'>(
    trimVariations ? (isMobile ? 1200 : 500) : 'auto'
  );

  const nonColorOptions = productFamily.options
    .map((option) => option.name.toLowerCase())
    .filter((option) => option !== 'color');

  const onClickAddToCart = (product: Product, position: number) => (quantity: number) => {
    const { shopify_graphql_id, shopify_variant_id } = product;

    const customAttributes = [
      ...getLineItemCustomAttributes(location.pathname),
      ...lineItemAttribution,
    ];

    const lineItem: LineItemToAdd = {
      variantId: shopify_graphql_id,
      quantity,
      customAttributes,
    };
    dispatch(addLineItems(checkoutId, [lineItem]));
    FacebookEvents.AddToCart([shopify_variant_id]);
    GoogleEvents.AddToCart([product]);
    AlgoliaEvents.AddToCart([product], user?.id?.toString());
    WebAnalytics.addToCart({ product, area: WebAnalyticsEventAreas.VARIANTS_VIEW, position: null });
  };

  const onOutOfStockClick = (product: Product) => {
    dispatch(
      InventoryModalActions.INVENTORY_MODAL_OPEN(product, WebAnalyticsEventAreas.VARIANTS_VIEW)
    );
  };

  const hasColors = productFamily.available_colors.length > 0;

  const hasClarities = products.length > 0 && products[0].clarities.length > 0;

  return (
    <div>
      <AnimateHeight height={height} easing="ease-out" duration={200}>
        <div className={styles.tableContainer}>
          <div className={styles.table} ref={containerRef}>
            <div className={styles.thead}>
              <div className={styles.row}>
                <div className={styles.cell}>{hasColors && 'color'}</div>
                {nonColorOptions.map((option) => (
                  <div className={styles.cell} key={option}>
                    {option}
                  </div>
                ))}
                <div className={styles.cell}>Stock</div>
                {hasClarities && <div className={styles.cell}>Water Clarity</div>}
                <div className={classNames(styles.cell)}>Price & Quantity</div>
              </div>
            </div>
            <div className={styles.tbody}>
              {products.map((product, i) => {
                const productFishingReports = fishingReports.filter((report) =>
                  fishingReportContainsSku(report, product.sku)
                );
                return (
                  <ProductRow
                    key={product.sku}
                    onClickAddToCart={onClickAddToCart(product, i)}
                    onOutOfStockClick={onOutOfStockClick}
                    onViewFishingReport={onViewFishingReport}
                    openAndScrollToFishingReportsPanel={openAndScrollToFishingReportsPanel}
                    product={product}
                    productFamily={productFamily}
                    productFishingReports={productFishingReports}
                    shopifyProduct={shopifyProduct}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </AnimateHeight>
      {trimVariations && height !== 'auto' && (
        <div className="flex -mt-12 flex-col items-center">
          <div className="bg-gradient-to-b from-[transparent] to-white h-12 w-full z-10 mb-3" />
          <OmniaButton kind="tertiary" size="lg" onPress={() => setHeight('auto')}>
            See All Variations
          </OmniaButton>
        </div>
      )}
    </div>
  );
});
