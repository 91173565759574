import { PlusCircleOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Affix, Badge, Dropdown, Popover } from 'antd';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useOnClickOutside } from '../../hooks/use_on_click_outside';
import { isAmbassador } from '../../lib/user';
import { ClickKey_TopNav, WebAnalytics } from '../../lib/web_analytics';
import { hasAccessToken } from '../../redux/auth';
import { CartActions, getLineItems } from '../../redux/cart';
import { FishingReportModalActions } from '../../redux/fishing_report_modal';
import { getUser } from '../../redux/user';
import { RoutePaths, WaterbodyPaths } from '../../routes';
import { AccountWidget } from '../account_widget/account_widget';
import { AmbassadorColorDot } from '../ambassador_color_dot/ambassador_color_dot';
import { ContentWrapper } from '../content_wrapper/content_wrapper';
import { ExploreMenu } from '../products_nav/explore_menu';
import { SearchUniversal } from '../search_universal/search_universal';
import SvgHamburgerShop from '../svg/hamburger_shop';
import SvgLock from '../svg/lock';
import SvgUser from '../svg/user';
import styles from './header_mobile.less';
import logo from './logo.svg';
import { MobileDrawer } from './mobile_drawer';

export const MOBILE_HEADER_HEIGHT_APPROX = 45;

export const HeaderMobile = ({
  searchModalVisibleRef,
}: {
  searchModalVisibleRef: MutableRefObject<boolean>;
}) => {
  const isLoggedIn = useSelector(hasAccessToken);
  const dispatch = useDispatch();
  const lineItemsCount = useSelector(getLineItems).length;
  const user = useSelector(getUser);
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const closeMenu = () => {
    setMenuOpen(false);
  };
  const openMenu = () => {
    setMenuOpen(true);
  };

  const widgetRef = useRef();
  const [widgetOpen, setWidgetOpen] = useState(false);

  const onCartClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(CartActions.CART_OPEN());
  };

  const onWidgetClick: React.MouseEventHandler = (e) => {
    if (widgetOpen === false) {
      e.stopPropagation();
      e.preventDefault();
      setWidgetOpen(true);
    }
  };
  useOnClickOutside(widgetRef, () => {
    if (widgetOpen) {
      setWidgetOpen(false);
    }
  });
  const onFishingReportClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(FishingReportModalActions.FISHING_REPORT_MODAL_OPEN());
  };

  useEffect(() => {
    setWidgetOpen(false);
  }, [location.pathname]);

  const mobileWidgetCard = <AccountWidget ref={widgetRef} />;
  const webAnalyticsTopNavHelper = (click_key: ClickKey_TopNav, target_url?: string) => {
    WebAnalytics.topNavClick({
      click_key,
      logged_in: isLoggedIn,
      target_url,
    });
  };

  const pathIsPdp = location.pathname.startsWith(RoutePaths.PRODUCTS);
  const pathIsMap =
    location.pathname === RoutePaths.MAP ||
    new RegExp(`^${RoutePaths.WATERS}/[^/]+${WaterbodyPaths.CURRENT_CONDITIONS}$`).test(
      location.pathname
    );
  const hideExploreMenu = pathIsPdp || pathIsMap;

  return (
    <>
      <header>
        <Affix>
          <div className={styles.header}>
            <ContentWrapper>
              <div className={styles.headerContent}>
                <SvgHamburgerShop
                  className={styles.hammy}
                  onClick={() => {
                    webAnalyticsTopNavHelper('[top_nav].(hamburger)');
                    openMenu();
                  }}
                />
                <Link
                  to={RoutePaths.HOME}
                  className={styles.logoLink}
                  onClick={() => webAnalyticsTopNavHelper('[top_nav].(logo)', RoutePaths.HOME)}
                >
                  <img src={logo} width={106} height={23} alt="Omnia Fishing" />
                </Link>

                <ul className={styles.nav}>
                  <li className={styles.navListItem}>
                    <div className={styles.reportButton} onClick={onFishingReportClick}>
                      <PlusCircleOutlined />
                    </div>
                  </li>
                  <li className={styles.navListItem}>
                    <SearchUniversal searchModalVisibleRef={searchModalVisibleRef} />
                  </li>
                  {isLoggedIn ? (
                    <li className={styles.navListItem} onClick={onWidgetClick}>
                      <Popover
                        overlayClassName={styles.popover}
                        open={widgetOpen}
                        content={mobileWidgetCard}
                        placement="bottomRight"
                      >
                        <div className={styles.widgetWrapper}>
                          <div>
                            <SvgUser className={styles.userIcon} />
                          </div>
                          {isAmbassador(user) && (
                            <AmbassadorColorDot user={user} dotSize={8} className={styles.dot} />
                          )}
                        </div>
                      </Popover>
                    </li>
                  ) : (
                    <li className={styles.navListItem}>
                      <span className={styles.widgetWrapper}>
                        <Dropdown
                          overlay={
                            <div className={styles.mobileDropdown}>
                              <div className={styles.dropdownLogin}>
                                <SvgUser className={styles.userIcon} />
                                <NavLink
                                  to={{
                                    pathname: RoutePaths.SIGNUP,
                                    state: {
                                      from: location.pathname,
                                    },
                                  }}
                                  onClick={() =>
                                    webAnalyticsTopNavHelper(
                                      '[top_nav].(signup)',
                                      RoutePaths.SIGNUP
                                    )
                                  }
                                >
                                  Sign Up
                                </NavLink>
                              </div>
                              <div className={styles.dropdownLogin}>
                                <SvgLock className={styles.lockIcon} />
                                <NavLink
                                  to={{
                                    pathname: RoutePaths.LOGIN,
                                    state: {
                                      from: location.pathname,
                                    },
                                  }}
                                  onClick={() =>
                                    webAnalyticsTopNavHelper('[top_nav].(login)', RoutePaths.LOGIN)
                                  }
                                >
                                  Log In
                                </NavLink>
                              </div>
                            </div>
                          }
                          placement="bottom"
                          trigger={['click']}
                        >
                          <SvgUser className={styles.userIcon} />
                        </Dropdown>
                      </span>
                    </li>
                  )}

                  <li className={styles.navListItem}>
                    <a
                      href="#"
                      className={styles.nav_link}
                      onClick={(e) => {
                        webAnalyticsTopNavHelper('[top_nav].(cart)');
                        onCartClick(e);
                      }}
                    >
                      <span className={styles.cartWrapper}>
                        <Badge count={lineItemsCount}>
                          <ShoppingCartOutlined className={styles.cartIcon} />
                        </Badge>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </ContentWrapper>
            <MobileDrawer menuOpen={menuOpen} closeMenu={closeMenu} />
          </div>
        </Affix>
        <div className={styles.subheader}>{hideExploreMenu ? null : <ExploreMenu />}</div>
      </header>
    </>
  );
};
