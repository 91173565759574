import { Waypoint } from '@omniafishing/core';
import React, { useCallback, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { getEnv } from '../../env';
import {
  AerisController,
  AerisUnits,
  BoatLanding,
  CORE_LAYER,
  LAYER_TYPES,
  MapLayerState,
  POINTS_OF_INTEREST_LAYER,
  WEATHER_LAYER,
} from './map_types';
import { waterClarityPaint, waterTempPaint } from './omnia_map_sources_paint';
const env = getEnv();

export const SOURCES = {
  cmapContour: 'https://socialmap.genesismaps.com/img/t_{quadkey}.png',
  cmapDepth: 'https://socialmap.genesismaps.com/img/b_{quadkey}.png',
  cmapVegetation: 'https://socialmap.genesismaps.com/img/v_{quadkey}.png',
  cmapHardness: 'https://socialmap.genesismaps.com/img/c_{quadkey}.png',
  mboxSatellite: 'mapbox://styles/mapbox/satellite-streets-v11',
  mboxStandard: 'mapbox://styles/omniafishing/cl8nijurz000u14o94ojffmkg',
  omniaBoatLandings: `https://api.mapbox.com/v4/omniafishing.0qmuypt7/{z}/{x}/{y}.vector.pbf?access_token=${env.MAPBOX_ACCESS_TOKEN}`,
  waterClarityUofM: `https://api.mapbox.com/v4/omniafishing.um-clarity-nov-vector/{z}/{x}/{y}.vector.pbf?access_token=${env.MAPBOX_ACCESS_TOKEN}`,
};

export const xWeatherLayerNames: { [key in WEATHER_LAYER]: string } = {
  [WEATHER_LAYER.WIND]: 'wind-particles',
  [WEATHER_LAYER.RADAR]: 'radar',
  [WEATHER_LAYER.AIR_TEMP]: 'temperatures',
  [WEATHER_LAYER.LIGHTNING]: 'lightning-strikes',
  [WEATHER_LAYER.STORMS]: 'stormcells',
};

const airTempTextLayerName = 'temperatures-text';

export enum InteractiveLayerIds {
  BOAT_LANDINGS = 'omnia_boat_landings_layer',
  WATERBODY_IDS = 'waterbody_ids_layer',
  OMNIA_WATER_TEMP = 'omnia_water_temp_layer',
  CLARITY = 'omnia_clarity_layer',
  WAYPOINTS = 'waypoints',
}

enum SourceIds {
  CMAP_CONTOUR = 'cmapContour',
  CMAP_DEPTH = 'cmapDepth',
  CMAP_HARDNESS = 'cmapHardness',
  CMAP_VEGETATION = 'cmapVegetation',
  OMNIA_BOAT_LANDINGS = 'omnia_boat_landings',
  OMNIA_CLARITY = 'omnia_clarity',
  OMNIA_CLARITY_COMPOSITE = 'omnia_clarity_composite',
  OMNIA_WATER_TEMP = 'omnia_water_temp',
  OMNIA_WATER_TEMP_COMPOSITE = 'omnia_water_temp_composite',
  WATERBODY_IDS = 'waterbody_ids',
  WAYPOINTS = 'waypoints ',
}

type Operator = '==' | '!=';
type BasicFilterType = [Operator, string | number, string | number];

const tempTextLayout: any = {
  'text-field': ['get', 'val'],
  'text-size': 14,
  'text-anchor': 'center',
  'text-padding': 10,
};
const tempTextPaint = {
  'text-color': '#000000',
  'text-halo-color': '#ffffff',
  'text-halo-width': 2,
  'text-halo-blur': 1,
};

interface OmniaMapSourcesProps {
  mapLayerState: MapLayerState;
  selectedBoatLanding: BoatLanding;
  selectedWaterbodyId: string | null;
  waterClarityDailyTile?: string | undefined;
  waterClarityShowComposite: boolean;
  waterTempDailyTile: string | null;
  waterTempShowComposite: boolean;
  waypoints: Waypoint[];
}

export const OmniaMapSources = (props: OmniaMapSourcesProps) => {
  const {
    mapLayerState,
    selectedWaterbodyId,
    waterClarityDailyTile,
    waterClarityShowComposite,
    waterTempDailyTile,
    waterTempShowComposite,
    waypoints,
  } = props;

  const waterbodyOutlineFilter: BasicFilterType = useMemo(
    () => ['==', 'waterbody_id', selectedWaterbodyId || ''],
    [selectedWaterbodyId]
  );

  const waterTempCompositeLayerVisible =
    mapLayerState[LAYER_TYPES.CORE] === CORE_LAYER.OMNIA_WATER_TEMP && waterTempShowComposite;
  const waterTempDailyLayerVisible =
    mapLayerState[LAYER_TYPES.CORE] === CORE_LAYER.OMNIA_WATER_TEMP &&
    waterTempDailyTile != null &&
    !waterTempShowComposite;

  const waterClarityCompositeLayerVisible =
    mapLayerState[LAYER_TYPES.CORE] === CORE_LAYER.CLARITY && waterClarityShowComposite;
  const waterClarityDailyLayerVisible =
    mapLayerState[LAYER_TYPES.CORE] === CORE_LAYER.CLARITY &&
    waterClarityDailyTile != null &&
    !waterClarityShowComposite;

  const waypointsLayerVisible = mapLayerState[LAYER_TYPES.POINTS_OF_INTEREST].includes(
    POINTS_OF_INTEREST_LAYER.WAYPOINTS
  );

  const convertToGeoJSON = useCallback((_waypoints: Waypoint[]) => {
    return {
      type: 'FeatureCollection',
      features: _waypoints.map((w) => ({
        type: 'Feature',
        id: w.id,
        properties: {
          ...w,
        },
        geometry: {
          type: 'Point',
          coordinates: [w.lng, w.lat],
        },
      })),
    };
  }, []);

  const geoJsonWaypoints = useMemo(() => {
    return convertToGeoJSON(waypoints || []) || [];
  }, [waypoints]);

  return (
    <>
      <Source
        type="vector"
        id={SourceIds.OMNIA_CLARITY_COMPOSITE}
        tiles={[
          `https://api.mapbox.com/v4/omniafishing.conus-composite-clarity/{z}/{x}/{y}.vector.pbf?access_token=${env.MAPBOX_ACCESS_TOKEN}`,
        ]}
      >
        <Layer
          // source-layer is the name of the layer in mapbox
          source-layer="omnia_clarity"
          id="clarity_composite_layer"
          type="fill"
          paint={waterClarityPaint}
          layout={{
            visibility: waterClarityCompositeLayerVisible ? 'visible' : 'none',
          }}
        />
      </Source>
      <Source
        type="vector"
        id={SourceIds.OMNIA_CLARITY}
        tiles={[
          `https://api.mapbox.com/v4/${waterClarityDailyTile}/{z}/{x}/{y}.vector.pbf?access_token=${env.MAPBOX_ACCESS_TOKEN}`,
        ]}
      >
        <Layer
          // source-layer is the name of the layer in mapbox
          source-layer="omnia_clarity"
          id={InteractiveLayerIds.CLARITY}
          type="fill"
          paint={waterClarityPaint}
          layout={{
            visibility: waterClarityDailyLayerVisible ? 'visible' : 'none',
          }}
        />
      </Source>
      <Source
        type="vector"
        id={SourceIds.OMNIA_WATER_TEMP_COMPOSITE}
        tiles={[
          `https://api.mapbox.com/v4/omniafishing.prod-composite-temp/{z}/{x}/{y}.vector.pbf?access_token=${env.MAPBOX_ACCESS_TOKEN}`,
        ]}
      >
        <Layer
          // source-layer is the name of the layer in mapbox
          source-layer="temperature_f"
          id="omnia_water_temp_composite_layer"
          type="fill"
          paint={waterTempPaint}
          layout={{
            visibility: waterTempCompositeLayerVisible ? 'visible' : 'none',
          }}
        />
      </Source>
      <Source
        type="vector"
        id={SourceIds.OMNIA_WATER_TEMP}
        tiles={[
          `https://api.mapbox.com/v4/${waterTempDailyTile}/{z}/{x}/{y}.vector.pbf?access_token=${env.MAPBOX_ACCESS_TOKEN}`,
        ]}
      >
        <Layer
          // source-layer is the name of the layer in mapbox
          source-layer="temperature_f"
          id={InteractiveLayerIds.OMNIA_WATER_TEMP}
          type="fill"
          paint={waterTempPaint}
          layout={{
            visibility: waterTempDailyLayerVisible ? 'visible' : 'none',
          }}
        />
      </Source>
      <Source
        type="vector"
        id={SourceIds.WATERBODY_IDS}
        tiles={[
          `https://api.mapbox.com/v4/omniafishing.omnia-waterbodies/{z}/{x}/{y}.vector.pbf?access_token=${env.MAPBOX_ACCESS_TOKEN}`,
        ]}
      >
        <Layer
          source-layer="waterbodies"
          id={InteractiveLayerIds.WATERBODY_IDS}
          type="fill"
          paint={{
            'fill-color': 'transparent',
          }}
        />
        <Layer
          source-layer="waterbodies"
          id={`waterbody_ids_outline`}
          type="line"
          paint={{
            'line-width': 2,
            'line-color': '#00FF1A',
          }}
          filter={waterbodyOutlineFilter}
        />
      </Source>

      <Layer
        source={SourceIds.OMNIA_WATER_TEMP}
        source-layer="temperature_f"
        id="omnia_water_temp_text_layer"
        type="symbol"
        minzoom={9.1}
        layout={{
          ...tempTextLayout,
          visibility: waterTempDailyLayerVisible ? 'visible' : 'none',
        }}
        paint={tempTextPaint}
      />

      <Layer
        source={SourceIds.OMNIA_WATER_TEMP_COMPOSITE}
        source-layer="temperature_f"
        id="omnia_water_temp_composite_text_layer"
        type="symbol"
        minzoom={9.1}
        layout={{
          ...tempTextLayout,
          visibility: waterTempCompositeLayerVisible ? 'visible' : 'none',
        }}
        paint={tempTextPaint}
      />

      <Source type="raster" id={SourceIds.CMAP_DEPTH} tiles={[SOURCES.cmapDepth]}>
        <Layer
          type="raster"
          id="cmapDepth"
          layout={{
            visibility:
              mapLayerState[LAYER_TYPES.CORE] === CORE_LAYER.CONTOUR_DEPTH ? 'visible' : 'none',
          }}
        />
      </Source>
      <Source type="raster" id={SourceIds.CMAP_HARDNESS} tiles={[SOURCES.cmapHardness]}>
        <Layer
          type="raster"
          id="cmapHardness"
          layout={{
            visibility:
              mapLayerState[LAYER_TYPES.CORE] === CORE_LAYER.CONTOUR_HARDNESS ? 'visible' : 'none',
          }}
        />
      </Source>
      <Source type="raster" id={SourceIds.CMAP_VEGETATION} tiles={[SOURCES.cmapVegetation]}>
        <Layer
          type="raster"
          id="cmapVegetation"
          layout={{
            visibility:
              mapLayerState[LAYER_TYPES.CORE] === CORE_LAYER.VEGETATION ? 'visible' : 'none',
          }}
        />
      </Source>
      <Source type="raster" id={SourceIds.CMAP_CONTOUR} tiles={[SOURCES.cmapContour]}>
        <Layer
          type="raster"
          id="cmapContour"
          layout={{
            visibility:
              mapLayerState[LAYER_TYPES.CORE] === CORE_LAYER.CONTOUR_DEPTH ||
              mapLayerState[LAYER_TYPES.CORE] === CORE_LAYER.VEGETATION ||
              mapLayerState[LAYER_TYPES.CORE] === CORE_LAYER.CONTOUR_HARDNESS
                ? 'visible'
                : 'none',
          }}
        />
      </Source>
      <Source type="vector" id={SourceIds.OMNIA_BOAT_LANDINGS} tiles={[SOURCES.omniaBoatLandings]}>
        <Layer
          // source-layer is the name of the layer in mapbox
          source-layer="Boat_Ramps_Tileset_1-aj38be"
          id={InteractiveLayerIds.BOAT_LANDINGS}
          type="symbol"
          layout={{
            'icon-size': 0.25,
            'icon-image': [
              'match',
              ['get', 'Type'],
              'Semi-Private',
              'semiPrivateIcon',
              'Public',
              'publicIcon',
              'publicIcon',
            ],
            'icon-allow-overlap': true,
            visibility: mapLayerState[LAYER_TYPES.POINTS_OF_INTEREST].includes(
              POINTS_OF_INTEREST_LAYER.BOAT_LANDINGS
            )
              ? 'visible'
              : 'none',
          }}
        />
      </Source>
      <Source type="geojson" id={SourceIds.WAYPOINTS} data={geoJsonWaypoints as any}>
        <Layer
          id={InteractiveLayerIds.WAYPOINTS}
          type="symbol"
          layout={{
            'icon-image': [
              'concat',
              ['get', 'waypoint_type'],
              [
                'case',
                ['==', ['get', 'img'], null], // Check if img is null
                '_icon', // If img is null, use this suffix
                '_icon_image', // If img is not null, use this suffix
              ],
            ],
            'icon-size': 0.25,
            'icon-anchor': 'bottom',
            'icon-allow-overlap': true,
            visibility: waypointsLayerVisible ? 'visible' : 'none',
          }}
        />
      </Source>
    </>
  );
};

const BASE_HEIGHT = 435;
const BASE_WIDTH = 641;
const BASE_COUNT = 20;
const BASE_PARTICLE_SIZE = 2;
const MAX_PARTICLE_SIZE = 2.4;
const baseTotalPixels = BASE_HEIGHT * BASE_WIDTH;

function calculateParticleCount(newHeight: number, newWidth: number) {
  const newTotalPixels = newHeight * newWidth;
  const scalingFactor = Math.pow(newTotalPixels / baseTotalPixels, 1 / 3);
  return Math.round(BASE_COUNT * scalingFactor);
}
function calculateParticleSize(newHeight: number, newWidth: number) {
  const newTotalPixels = newHeight * newWidth;
  const scalingFactor = Math.pow(newTotalPixels / baseTotalPixels, 1 / 3);

  // Calculate the new particle size, ensuring it does not go below BASE_PARTICLE_SIZE
  let newSize = BASE_PARTICLE_SIZE * scalingFactor;

  // Ensure the new size does not exceed the maximum size
  newSize = Math.min(newSize, MAX_PARTICLE_SIZE);

  // If the new size is less than the base size, set it to the base size
  if (newTotalPixels <= baseTotalPixels) {
    newSize = BASE_PARTICLE_SIZE;
  }

  return newSize;
}

export const applyAerisLayerController = (args: {
  activeCoreLayer: CORE_LAYER;
  activeWeatherLayers: WEATHER_LAYER[];
  aerisController: AerisController;
  aerisUnits: AerisUnits;
  mapHeight: number;
  mapWidth: number;
  prevWeatherLayers: WEATHER_LAYER[];
  weatherLayersToRemove: WEATHER_LAYER[];
}) => {
  const {
    activeCoreLayer,
    activeWeatherLayers,
    aerisController,
    aerisUnits,
    mapHeight,
    mapWidth,
    prevWeatherLayers,
    weatherLayersToRemove,
  } = args;

  const particleCount = calculateParticleCount(mapHeight, mapWidth);
  const particleSize = calculateParticleSize(mapHeight, mapWidth);

  weatherLayersToRemove.forEach((layer) => {
    const aerisLayer = xWeatherLayerNames[layer];
    if (aerisLayer && aerisController.hasWeatherLayer(aerisLayer)) {
      if (
        layer === WEATHER_LAYER.AIR_TEMP &&
        aerisController.hasWeatherLayer(airTempTextLayerName)
      ) {
        aerisController.removeWeatherLayer(airTempTextLayerName);
      }
      aerisController.removeWeatherLayer(aerisLayer);
    }
  });

  activeWeatherLayers.forEach((layer) => {
    const aerisLayer = xWeatherLayerNames[layer];
    const activeLayer = aerisController.hasWeatherLayer(aerisLayer);
    if (aerisLayer && !activeLayer) {
      if (aerisLayer === xWeatherLayerNames[WEATHER_LAYER.LIGHTNING]) {
        aerisController.addWeatherLayer(aerisLayer, {
          ...LIGHTNING_CONFIG,
        });
      } else if (aerisLayer === xWeatherLayerNames[WEATHER_LAYER.STORMS]) {
        aerisController.addWeatherLayer(aerisLayer, {
          paint: {
            opacity: 0.5,
          },
        });
      } else if (aerisLayer === xWeatherLayerNames[WEATHER_LAYER.RADAR]) {
        aerisController.addWeatherLayer(aerisLayer, {
          paint: {
            opacity: 0.6,
          },
        });
      } else if (aerisLayer === xWeatherLayerNames[WEATHER_LAYER.AIR_TEMP]) {
        aerisController.addWeatherLayer(aerisLayer, {
          paint: {
            opacity: 0.4,
          },
          data: {
            // quality: 'minimal',
            evaluator: {
              title: 'Air Temperature',
              fn: (value: number) => aerisUnits.CtoF(value).toFixed(0) + '&deg;F',
            },
          },
        });
        aerisController.addWeatherLayer(airTempTextLayerName, {});
      } else if (aerisLayer === xWeatherLayerNames[WEATHER_LAYER.WIND]) {
        aerisController.addWeatherLayer(aerisLayer, {
          paint: {
            particle: {
              count: particleCount,
              size: particleSize,
              speed: 1.7,
              trails: true,
              trailsFade: 0.9,
              dropRate: 0.002,
              dropRateBump: 0.01,
            },
          },
        });
      } else {
        aerisController.addWeatherLayer(aerisLayer);
      }
    }
  });

  const waterTempNotEngaged = activeCoreLayer !== CORE_LAYER.OMNIA_WATER_TEMP;
  const waterClarityNotEngaged = activeCoreLayer !== CORE_LAYER.CLARITY;

  if (activeWeatherLayers.length && waterTempNotEngaged && waterClarityNotEngaged) {
    aerisController.addDataInspectorControl();
    const dataInspectorControl = aerisController.controls.dataInspector;
    dataInspectorControl.enable();
  } else if (prevWeatherLayers.length) {
    const dataInspectorControl = aerisController.controls.dataInspector;
    // this can be undefined in some cases
    dataInspectorControl?.disable();
  }
};

export const LIGHTNING_SHADER = `
#extension GL_OES_standard_derivatives : enable

precision mediump float;

uniform vec2 resolution;
uniform float dpr;
uniform float time;

varying vec2 vUv;
varying vec2 vPosition;
varying float vFactor;
varying float vRandom;

float rand(float x) {
return fract(sin(x)*75154.32912);
}

float rand3d(vec3 x) {
return fract(375.10297 * sin(dot(x, vec3(103.0139,227.0595,31.05914))));
}

float noise(float x) {
float i = floor(x);
float a = rand(i), b = rand(i+1.);
float f = x - i;
return mix(a,b,f);
}

float perlin(float x) {
float r=0.,s=1.,w=1.;
for (int i=0; i<6; i++) {
s *= 2.0;
w *= 0.5;
r += w * noise(s*x);
}
return r;
}

float noise3d(vec3 x) {
vec3 i = floor(x);
float i000 = rand3d(i+vec3(0.,0.,0.)), i001 = rand3d(i+vec3(0.,0.,1.));
float i010 = rand3d(i+vec3(0.,1.,0.)), i011 = rand3d(i+vec3(0.,1.,1.));
float i100 = rand3d(i+vec3(1.,0.,0.)), i101 = rand3d(i+vec3(1.,0.,1.));
float i110 = rand3d(i+vec3(1.,1.,0.)), i111 = rand3d(i+vec3(1.,1.,1.));
vec3 f = x - i;
return mix(mix(mix(i000,i001,f.z), mix(i010,i011,f.z), f.y),
       mix(mix(i100,i101,f.z), mix(i110,i111,f.z), f.y), f.x);
}

float perlin3d(vec3 x)
{
float r = 0.0;
float w = 1.0, s = 1.0;
for (int i=0; i<5; i++) {
w *= 0.5;
s *= 2.0;
r += w * noise3d(s * x);
}
return r;
}



#define COL1 vec4(0, 0, 0, 0) / 255.0
#define COL2 vec4(235, 241, 245, 255) / 255.0

#define SIZE 100
#define FLASH_POWER .8
#define RADIUS .01
#define SPEED .0018
#define SEED

void main() {
vec2 pos = vUv;

float dist = length(2.0 * pos - 1.0) * 2.0;

float x = time + 0.1;

float m = 0.2 + 0.2 * vFactor; // max duration of strike
float i = floor(x/m);
float f = x/m - i;
float k = vFactor; // frequency of strikes
float n = noise(i);
float t = ceil(n-k); // occurrence
float d = max(0., n-k) / (1.-k); // duration
float o = ceil(t - f - (1. - d)); // occurrence with duration

float fx = 4.;
if (o == 1.) {
fx += 10. * vFactor;
}

fx = max(4., fx);
float g = fx / (dist * (10. + 20.)) * FLASH_POWER;

vec4 color = mix(COL1, COL2, g);
color.a *= min(1.0, 0.5 + vFactor);

gl_FragColor = color;
gl_FragColor.rgb *= gl_FragColor.a;
}
            `;

export const LIGHTNING_CONFIG = {
  type: 'symbol',
  source: 'lightning',
  paint: {
    symbol: {
      shader: LIGHTNING_SHADER,
      size: { width: 60, height: 60 },
      animated: true,
      blending: 2,
      pitchWithMap: true,
      factor: (data: { age: number }) => {
        const max = 200;
        return (max - data.age) / max;
      },
    },
  },
};
