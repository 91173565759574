import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../constants/query_keys';
import { apiV1 } from '../lib/api';
import { FIVE_MINUTES_IN_MS } from '../lib/time';

export const useRewardsPercentages = () => {
  const { data } = useQuery({
    queryKey: QueryKeys.REWARDS_PERCENTAGES,
    queryFn: async () => {
      const response = await apiV1.cartRewardsPercentages();
      return response.data.data;
    },
    staleTime: FIVE_MINUTES_IN_MS,
  });

  const single_rewards_percent = data?.single_rewards_percent || 0.1;
  const double_rewards_percent = data?.double_rewards_percent || 0.2;

  return {
    single_rewards_percent,
    double_rewards_percent,
  };
};
